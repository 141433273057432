<template>
  <div id="app">
    <b-card v-if="loading">
      <div class="d-flex justify-content-center">
        <b-spinner label="Loading..." />
      </div>
    </b-card>
    <b-card
      v-else
      :title="editing ? 'Edit version' : 'New version'"
    >
      <ckeditor
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"
      />
      <small
        v-if="editorError"
        class="text-danger mt-1"
      >This field is required and must be more than 10 character</small>
      <b-row class="mt-1 text-right">
        <b-col>
          <b-button
            v-if="editing"
            @click="update"
          >
            Update
          </b-button>

          <b-button
            v-else
            @click="save"
          >
            Save
          </b-button>

          <b-button
            class="ml-1"
            @click="$router.push({name:'release-notes'})"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2'

export default {
  name: 'App',
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      loading: false,
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        language: 'en',
      },
      id: null,
      editing: null,
      editorError: false,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      this.editing = true
      this.loadData()
    } else {
      this.editorData = '<p><strong>Version vXX</strong></p><p>release date: mm/dd/yyyy</p><ol><li>XXX.</li></ol>'
    }
  },
  methods: {
    loadData() {
      this.loading = true
      this.$http.get(this.$resources.releaseNotes.show.replace(':id', this.id)).then(response => {
        const { data: { status, data } } = response

        switch (status) {
          case 200:
            this.editorData = data.release.text
            break
          default:
            this.$notify({ type: 'error', title: 'Service Not Available', text: `CODE: ${status}` })
            this.$router.replace({ name: 'release-notes' })
        }

        this.loading = false
      })
    },
    save() {
      if (this.validateEditor()) {
        this.loading = true
        this.editorError = false

        this.$http.post(this.$resources.releaseNotes.store, { text: this.editorData }).then(response => {
          const { data: { status } } = response

          switch (status) {
            case 200:
              this.$router.push({ name: 'release-notes' })
              break
            default:
              this.$notify({ type: 'error', title: 'Service Not Available', text: `CODE: ${status}` })
          }

          this.loading = false
        })
      }
    },
    update() {
      if (this.validateEditor()) {
        this.loading = true
        this.editorError = false

        this.$http.post(this.$resources.releaseNotes.update.replace(':id', this.id), { text: this.editorData }).then(response => {
          const { data: { status } } = response

          switch (status) {
            case 200:
              this.$router.push({ name: 'release-notes' })
              break
            default:
              this.$notify({ type: 'error', title: 'Service Not Available', text: `CODE: ${status}` })
          }

          this.loading = false
        })
      }
    },
    validateEditor() {
      this.editorError = (this.editorData.length <= 10)
      return !this.editorError
    },
  },
}
</script>
